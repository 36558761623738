p.smallText {
  font-weight: thin;
  font-size: 12px;
}

ion-card,
ion-list {
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}

ion-item {
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.center-wrapper {
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.bold {
  font-weight: bold !important;
}
